@import "src/scss/base";

.container {
  background: $lt-grey;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
  
}

.infoContainer {
  @include text;
  @include column-size(2, 0);

  img {
    max-width: 450px;
  }

  @include sizes(0, 1200) {
    margin-bottom: 6rem;
    @include column-size(1, 0);

    img {
      max-width: 300px;
    }
  }
}

.button {
  @include unstyled-button;
  position: absolute;
  margin-top: rem(-50);
  margin-left: rem(350);
}

.footer {
  font-size: rem(14);
  position: absolute;
  margin: rem(-50) rem(50);
}
