@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include text;
  @include column-size(1.2, 0);
  align-self: center;

  img {
    max-width: 600px;
  }

  @include sizes(600, 1200) {
    @include column-size(1, 0);
    margin-bottom: 2rem;
    img {
      max-width: 600px;
    }
  }

  @include sizes(0, 600) {
    @include column-size(1, 0);

    img {
      max-width:325px;
    }
  }
}

#dashEffect {
  @include end-dash-right;
}

.image {
  transition: all 0.3s ease-in-out;
  &:after {
    content: "";
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    cursor: pointer;
  }

  &:hover {
    &:after {
      width: 110%;
      height: 110%;
      position: absolute;
      top: -5%;
      left: -5%;
      border-radius: 3px;
      background-color: transparentize(#1d1d1d, 0.5);
    }

    .grid-image-download {
      opacity: 1;
      display: block;
      transition: all 0.3s ease-out;
    }
  }
}
