@import "src/scss/base";

.container {
  font-family: $sans-serif-thin;
  margin-bottom: 2rem;
}

.containerGrey {
  font-family: $sans-serif-thin;

  @include sizes(0, 1200) {
  background: $lt-grey;
  padding-bottom:2rem;
  }
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
  }
}

.list {
  @include plain-list;
  font-family: $sans-serif;
}

.listWrapper {
  display: flex;

  @include sizes(0, 1200) {
    flex-direction: column;
    justify-content: center;
  }
}

.plainList {
  @include black-bullet-list;
  font-family: $sans-serif;
  font-size: rem(16);
  li {
    margin: 0 1rem;
  }
}

.item {
  margin: rem(5) 0 rem(25) !important;
}

.header {
  @include subheader-small;
  margin: 1rem 0;
}

.subHeader {
  font-family: $sans-serif-bold;
}

.subHeaderThin {
  display: block;
  margin: -1rem 0 1rem 0;
}

.text {
  line-height: 1.5;
  font-size: rem(24);

  @include sizes(0, 1200) {
    font-size: rem(16);
  }
}

.columnContainer {
  display: flex;
  margin-left: -10%;

  @include sizes(0, 600) {
    margin-left: 0;
    flex-direction: column;
    justify-content: center;
  }

  @include sizes(600, 1200) {
    margin-left: 0;
  }

  @include sizes(1200, 1400) {
    margin-left: -150px;
  }
}

.column {
  @include column-size(3, 50);

  @include sizes(0, 600) {
    @include column-size(1, 50);
  }
}

.wideColumn {
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 50);
  }
}

.image {
  width: 500px;
  padding: 2rem;
  margin-top: rem(25);
  box-shadow: 3px 3px 5px $grey;

  @include sizes(0, 1200) {
    width: 90%;
    padding: 0;
  }
}
