$header-height: rem(75);

//!This was the container hieght without the scroll effect of the header. Keeping it here in case we want to/need to leverage the calc eventually.
// $container-height-mobile: calc(100vh - #{$header-height});
$container-height: 100vh;

@mixin end-dash-right {
  position: relative;

  &::after {
    content: "";
    background-color: $gold;
    display: block;
    position: absolute;
    right: -20vw;
    bottom: 50%;
    z-index: -1;
    height: rem(4);
    width: 50vw;
    animation: from-right 750ms ease-in forwards;
    animation-delay: 500ms;
  }
}

@mixin end-dash-right-small {
  position: relative;

  &::after {
    content: "";
    background-color: $gold;
    display: block;
    position: absolute;
    right: -40vw;
    bottom: 50%;
    z-index: -1;
    height: rem(4);
    width: 50vw;
    animation: from-right 750ms ease-in forwards;
    animation-delay: 500ms;

    @include sizes(0, 600) {
      bottom: 20%;
      right: -28vw;
    }

    @include sizes(600, 800) {
      right: -30vw;
    }
  }
}
