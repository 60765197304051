@import "src/scss/base";

.container {
  position: relative;
  color: $grey;
  font-family: $sans-serif-bold;
  text-transform: uppercase;
  display: block;
  float: right;
  margin-top: -12%;

  @include sizes(1200, 1400) {
    // margin-top: -20%;
  }

  @include sizes(0, 1200) {
    display: none;
  }
}

.title {
  font-size: 14px;
}

.number {
  font-size: rem(155);

}
