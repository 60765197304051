@import "src/scss/base";

.container {
  color: $white;
}

.imgContainer {
  background-image: url(/images/style_guide_01-man-woman-couch.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: $container-height;

  @include sizes(0, 600) {
    height:300px;
  }

  @include sizes(0, 1200) {
    background-position: center;
    margin-top:$header-height;
  }

  @include sizes(600, 1200) {
    height:500px;
  }
}

.textContainer {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  margin-left: rem(50);


  @include sizes(0, 1200) {
    width: 100%;
    height: 100%;
    justify-content: center;
    margin-left: rem(0);

  }
}

.textInner {
  margin-bottom: rem(65);
  text-align: left;


  @include sizes(0, 1200) {
    margin-bottom: rem(45);
    text-align: center;
  }
}

.title {
  @include section-heading;

  @include sizes(0, 1200) {
    width: 100%;
  }
}
 
.subTitle {
  @include subheader-thin;
}
