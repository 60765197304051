@import "src/scss/base";

.container {
  background: $lt-grey;

  ul {
    @include black-bullet-list;
  }
}

.wrapper {
  @include container-default;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include sizes(0, 1200) {
    margin-bottom: 6rem;
  }
}

.innerContainer {
  display: flex;
  width: 95%;
}

.iconLockup {
  @include text;
}

.header {
  @include subheader-small;
  margin: 5rem 0 1rem;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: space-evenly;
  min-width: 600px;

  @include sizes(0, 1200) {
    min-width: 100%;
  }
}

.subText {
  width: 55%;
  font-size: rem(14);
  font-family: $sans-serif-medium;
  text-align: center;
  align-self: center;
}

.imageInner {
  display: flex;
  flex-direction: column;

  img {
    padding: rem(25);
    align-self: center;
    max-width: 180px;

    @include sizes(0, 1200) {
      width: 100%;
    }

    @include sizes(1450, 1600) {
      max-width: 150px;
    }

    @include sizes(1200, 1450) {
      max-width: 125px;
    }
  }
}

.button {
  @include unstyled-button;
  width: 50px;
  align-self: flex-end;
}

.disclaimer {
  font-size: rem(14);
  position: absolute;
  margin: rem(-100) rem(50);
  font-family: $sans-serif-medium;

  @include sizes(0, 1200) {
    position: relative;
    margin: 0;
    padding: rem(0) rem(10) rem(10) rem(10);
  }
}
