@import "src/scss/base";

.textLockup {
  @include header-dash;
  margin: rem(50);
  font-size: rem(20);
  font-family: $sans-serif-medium;
  text-transform: uppercase;
  font-style: italic;
  position: absolute;

  @include sizes(0, 1400) {
    margin: rem(25);
    padding:1rem 0;
    position: relative;
  }
}
