@import "src/scss/base";

.grid-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
  &:after {
    content: "";
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .grid-image-download {
    opacity: 0; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    z-index: 5;
  }

  @media (min-width: 769px) {
    &:hover {
      &:after {
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 3px;
        background-color: transparentize($lt-teal, 0.5);
      }

      .grid-image-download {
        opacity: 1;
        display: block;
        transition: all 0.3s ease-out;
      }
    }
  }
}

#image-enlarge {
  padding: 0;
  width: 325px;
  max-width: 100%;
  margin-right: rem(-25);
  margin-top: rem(15);

  @include sizes(0, 1200) {
    max-width: 275px;
  }

  @include sizes(1200, 1500) {
    max-width: 225px;
  }
}
