@import "src/scss/base";

.imgContainer {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: $container-height;

  @include sizes(0, 600) {
    height:300px;
  }

  @include sizes(0, 1200) {
    background-position: center;
  }

  @include sizes(600, 1200) {
    height:500px;
  }
}

.textContainer {
  height: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include sizes(0, 1200) {
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
  }
}

.textInner {
  margin-bottom: rem(45);
  margin-right: rem(25);

  @include sizes(0, 1200) {
    margin-bottom: rem(0);
    margin-right: rem(0);
  }
}

.title {
  @include section-heading;

  @include sizes(0, 1200) {
    font-size: rem(40);
  }
}
