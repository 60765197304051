@import "src/scss/base";

.container {
  background: $lt-grey;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include text;
  @include column-size(1.5, 0);

  img {
    max-width: 600px;
  }

  @include sizes(0, 1200) {
    @include column-size(1, 50);
    margin: 0 0 4rem;

    img {
      width: 100%;
      display: block;
      margin: auto;
    }
  }
}
