@import "src/scss/base";

.container {
  background: $lt-grey;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;

  @include sizes(0, 1200) {
    justify-content: center;
      margin-bottom: 2rem;
  }
}

.infoContainer {
  img {
    max-width: 600px;

    @include sizes(0, 600) {
      max-width: 100%;
    }
    @include sizes(600, 1200) {
      max-width: 50%;
    }

  }
}
 