@import "src/scss/base";

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  display: flex;
  align-items: center;
  @include text;

  @include sizes(0, 600) {
    flex-direction: column;
  }
}

.infoWrapper {
  margin: 0 2rem;
}

.header {
  @include subheader-small;
}

.cardWhite {
  display: flex;
  flex-direction: column;
  width: 160px;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  border: 3px solid $gold;

  @media (max-width: 810px) {
    margin-top: 2rem;
  }
}

.cardNumber {
  color: $teal;
  font-size: 2.2rem;
  line-height: 0.8;
  margin: 0;
  font-family: $sans-serif;
}

.plus {
  color: $dk_blue;
  margin: 0;
  padding: 0 1rem;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  background-color: $white;
  transform: translateY(-2rem);
}

.subline {
  margin: 0;
  font-size: 1rem;
  text-align: center;
}

.sublineBlue {
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: $teal;
  text-transform: uppercase;
  margin: 0.2rem 0 0.1rem;
  text-align: center;
}

.ctaDisclosure {
  text-align: center;
  margin: 0.1rem 0;
  font-size: rem(12);
  line-height: 1.2;
}

.image {
  width: rem(450);
  align-self: center;

  @include sizes(0, 1400) {
    width: rem(250);
  }
}

.stroke {
  height: rem(300);
  margin: 0 0 0 5.5rem;
  border-right: 2px solid $lt-grey;
  @include sizes(0, 1400) {
    margin: 0 0 0 3rem;
  }

  @include sizes(0, 600) {
    border-right: none;
    height: 0;
    width: rem(300);
    border-bottom: 2px solid $lt-grey;
    margin: 2.5rem 0 0 0;
  }
}

#dashEffect {
  @include end-dash-right-small;
}
