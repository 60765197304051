// h2 section
@mixin section-heading {
  @include fontSize(40);
  font-family: $sans-serif-bold;
  line-height: 1;
  color: $white;
  // margin: 2rem 0 1.5rem;
  text-transform: uppercase;
  text-shadow: 0px 0px 10px $dk_grey;

  @include sizes($medium) {
    @include fontSize(46);
    // margin: 3rem 0 1.5rem;
  }

  @include sizes($medium-large) {
    @include fontSize(54);
  }
}

@mixin subheader-thin {
  @include fontSize(36);
  font-family: $sans-serif-thin;
  margin: 1rem 0;
  padding: 0;
  text-transform: uppercase;
  text-shadow: 0px 0px 10px $dk_grey;

  @include sizes(0, $medium) {
    @include fontSize(24);
  }
}

//H3
@mixin subheader-small {
  @include fontSize(24);
  margin: 1rem 0;
  padding: 0;
  text-transform: uppercase;

  @include sizes(0, $medium) {
    @include fontSize(18);
  }
}

//H3
@mixin subheader {
  @include fontSize(36);
  margin: 1rem 0;
  padding: 0;
  text-transform: uppercase;

  @include sizes(0, $medium) {
    @include fontSize(24);
  }
}

@mixin header-underlined {
  @include fontSize(45);
  display: block;
  position: relative;
  color: $text;
  font-family: $sans-serif-thin;
  padding: 0;
  text-transform: uppercase;

  @include sizes(0, 1200) {
    @include fontSize(36);
  }
}

@mixin text {
  @include fontSize(16);
  line-height: 1.5;
  color: $text;
  margin: 1rem 0;

  @include sizes($medium) {
    @include fontSize(18);
  }
}

//lists
@mixin plain-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 1rem 0;
  }
}

@mixin black-bullet-list {
  @include plain-list;
  line-height: 1.2;
  padding-left: 1em;

  li {
    &::before {
      content: "\2022"; // \25CF 2022
      color: $text;
      font-size: 1.5rem;
      font-weight: bold;
      display: inline-block;
      width: 0.75em;
      // margin-left: -0.75em;
      margin: 0 0 0 -0.75em;
    }
  }
}

@mixin black-circle-list {
  @include plain-list;
  line-height: 1.2;
  padding-left: 1em;

  // li {
  &::before {
    content: "\25CB";
    color: $text;
    font-size: .8rem;
    font-weight: bold;
    display: inline-block;
    width: 0.75em;
    margin: 0 5px 0 -0.75em;
  }
}

@mixin white-bullet-list {
  @include plain-list;
  color: $white;
  line-height: 1;
  padding-left: 1em;

  li {
    &::before {
      content: "\2022"; // \25CF 2022
      color: $white;
      font-size: 1.5rem;
      font-weight: bold;
      display: inline-block;
      width: 0.75em;
      margin-left: -0.75em;
    }
  }
}

@mixin check-list {
  @include plain-list;
  line-height: 1.2;
  padding-left: 1.2em;
  font-family: $sans-serif-bold;

  li {
    &::before {
      content: url(/images/svg/checkmark-icon.svg); // \25CF 2022
      font-size: 1.5rem;
      font-weight: bold;
      display: inline-block;
      width: 0.75em;
      margin: 0 0 0 -0.75em;
      padding-right: 0.75rem;
    }
  }
}

@mixin header-dash {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: $gold;
    display: block;
    width: rem(150);
    height: rem(2);
    animation: from-left 750ms ease-in forwards;
  }
}

@keyframes from-left {
  0% {
    width: rem(0);
  }
  50% {
    width: rem(75);
  }
  100% {
    width: rem(150);
  }
}

@keyframes from-right {
  0% {
    right: -200rem;
  }
  50% {
    right: -50rem;
  }
  100% {
    right: 100;
  }
}
