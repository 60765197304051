@import 'mixins';
@import 'colors';
@import 'fonts';
@import 'text';
@import 'buttons';
@import 'sizes';
@import 'globals';


/* Universal breakpoints */
$large : 1280;
$medium-large : 992;
$medium : 768;
$small : 480;
$xsmall : 360;

$sans-serif : 'Synchrony Sans', Helvetica, sans-serif;
$sans-serif-thin : 'Synchrony Sans Thin', Helvetica, sans-serif;
$sans-serif-medium : 'Synchrony Sans Medium', Helvetica, sans-serif;
$sans-serif-bold : 'Synchrony Sans Bold', Helvetica, sans-serif;

$default_margin : 150;

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;