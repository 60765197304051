@import "src/scss/base";

.locations {
  @include sizes(0, 900) {
    max-width: 300px;
    margin: 0 auto;
  }
  @include sizes(900, 0) {
    border-top: 1px solid $teal;
    margin: 1rem 0 0;
  }

  li {
    text-decoration: none;
    color: $text;
    @include sizes(0, 900) {
      margin: 0.5rem 0;

    }
  }

  p {
    color: $text;
    text-decoration: none;

  }
  &__2up {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  &__item {
    &:first-of-type {
      margin-bottom: 1rem;
      .locations__heading:before {
        background-image: url('/images/svg/icon_map-pin-syf.svg');
      }
    }
  }

  &__list {
    // @include plain-list;
    padding-left: calc(0.5rem + 36px);

    &-item {
      display: flex;
      align-items: center;
      // padding-bottom: 0.5rem;
    }

    &-icon {
      img {
        width: rem(20);
        height: auto;
        margin-right: rem(12);
      }

    }
  }

  &__heading {
    position: relative;
    font-size: 16px;
    font-weight: $font-bold;
    text-transform: uppercase;
    color: $teal;
    margin: 0 0 0 rem(44);
    display: flex;
    align-items: flex-start;

    &-icon {
      width: rem(36);
      height: auto;
      margin-right: 0.5rem;
    }

    &:before {
      content: '';
      background-image: url('/images/svg/icon_map-pin.svg');
      background-size: cover;
      background-position: center;
      display: block;
      width: rem(36);
      height: rem(36);
      left: rem(-44);
      top: 0;
      position: absolute;
    }
  }

  &__subheading {
    font-size: rem(16);
    margin: 0 0 1rem;
    padding-left: calc(14px + 30px);
  }

  @include sizes(900, 0){
    padding: 0 0 2rem;
    &__2up {
      flex-direction: row;
      justify-content: space-between;
    }

    &__item {

      &:first-of-type {
        margin-right: 2rem;
        margin-bottom: 0;
      }
    }

    &__list {
      &-item {
        // font-size: rem(18);
      }
    }

    &__heading {
      font-size: rem(18);
      margin: 0 0 rem(2) rem(44);
    }

    &__subheading {
      font-size: rem(16);
      font-weight: $font-medium;
      margin: 0 0 1.25rem;
    }
  }

  @include sizes($large){

    &__list {
      &-item {
        font-size: rem(14);
        // padding-bottom: rem(5);
      }
    }

    &__heading {
      font-size: rem(20);
    }

    &__subheading {
      font-size: rem(16);
    }
  }
}