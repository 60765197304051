@import "src/scss/base";

.container {
  color: $white;
  background-color: $teal;
  height: $header-height;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 0;
  width: 100%;
  position: fixed;
  padding: rem(15);
  box-sizing: border-box;
  z-index: 6;
  transition: top 0.5s;

  @include sizes(0, 1200) {
    justify-content: space-between;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  margin: 3rem;
  cursor: pointer;

  span {
    font-size: rem(24);
    margin: 0 1rem;
    text-transform: uppercase;
    @include sizes(0, 1200) {
      font-size: rem(18);
    }
  }

  @include sizes(0, 1400) {
    margin: 1rem 0;
  }
}

.logo {
  width: rem(80);
  align-self: center;
}

.innerWrapper {
  display: flex;
  align-items: center;


  @include sizes(0, 1200) {
    display: none;
  }
}

.links {
  font-size: rem(16);
  height: 35px;

  a {
    margin: 1rem;
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
  @include link-underline-effect;
}

.button {
  display: inline-block;
  color: $gold;
  font-family: $sans-serif-bold;
  border: 2px solid $gold;
  border-radius: rem(5);
  padding: rem(10);
  font-size: rem(16);
  background: none;
  text-decoration: none;
  &:hover {
    @include button-hover;
    a {
      color: $white;
    }
  }
}

.buttonInner {
  color: $gold;
  text-decoration: none;
  display: block;
  margin-top: 4px;
  &:hover {
    color: $white;
  }
}

.tipsContainer {
  display: inline-block;
}

.quickTipsButton {
  font-size: rem(16);
  height: 35px;
  margin: 0 2rem 0 0;
  color: $white;
  border: none;
  background: none;
  cursor: pointer;  

   @include sizes(0, 500) {
    margin: 0;
   }
  span {
    @include sizes(0, 500) {
      display: none;
      }
    }
}

.arrowContainer {
  display: inline-block;
  height: 18px;
}

.quickTipsButtonIcon {
  margin: 0 rem(5) rem(-1);
}

.alertContainer {
  position: absolute;
  background: $gold;
  color: $dk-grey;
  font-family: $sans-serif-bold;
  border-radius: 50%;
  margin-left: 110px;
  margin-top: -40px;
  height: 25px;
  width: 25px;

  @include sizes(0, 500) {
    margin-left: 20px;
    margin-top: -50px;
  }
}

.alert {
  height: 25px;
  width: 24px;
  text-align: center;
  margin-top: 5px;
}

.innerWrapperMobile {
  display: flex;
  align-items: center;
  @include sizes(1200, 0) {
    display: none;
  }
}

.mobileMenuButton {
  font-size: rem(16);
  height: 35px;
  color: $white;
  border: none;
  background: none;
  cursor: pointer;
}

.hamburgerIcon {
  width:25px;
}
