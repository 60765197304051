@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sizes(0, 1200) {
    background: #f0f0f0;
  }
}

.innerContainer {
  @include column-size(1.2, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
    margin: 2rem 0;
  }

  @include sizes(1200, 1400) {
    margin-left:50px;
  }
}

.header {
  @include subheader-small;
}

.subHeader {
  font-size: rem(20);

  @include sizes(0, 1200) {
    font-size: rem(16);
  }
}

.listContainer {
  margin: 0 1rem;
  font-size: rem(16);

  @include sizes(0, 1200) {
    font-size: rem(16);
  }
}

.list {
  @include black-bullet-list;
  li {
    margin: rem(5) 0;
  }
}

.italic {
  font-style: italic;
}
