@import "src/scss/base";

.container {
  overflow-y: auto;
  color: $text;
  box-sizing: border-box;
  margin-top: $header-height;
  width: 100%;
  box-shadow: -5px -1px 9px rgba(0, 0, 0, 0.12);
  background: $lt-grey;
  position: fixed;
  right: 0;
  z-index: 2;
  padding: rem(10) rem(10) rem(10) rem(10);
  transition: top 0.5s;

  @include sizes(1200, 0) {
    display: none;
  }
}

.content {
  color: $text;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.innerContent {
  padding: rem(15);
  background: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0 rem(5) rem(16) rem(5);
  border-radius: 2px;
  position: relative;
}

.links {
  font-size: rem(18);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  a {
    margin: 0;
    margin: 1rem;
    color: $text;
    text-decoration: none;
    cursor: pointer;
    width: 125px;
    &::after {
      content: "";
      background-color: $gold;
      display: block;
      width: rem(125);
      height: rem(2);
    }
  }
  @include link-underline-effect-dark;
}

.button {
  color: $gold;
  font-family: $sans-serif-bold;
  border: 2px solid $gold;
  border-radius: rem(5);
  padding: rem(10);
  font-size: rem(16);
  background: $teal;
  margin: 0 auto;
  display: block;
  width: 165px;
  &:hover {
    @include button-hover;
    a {
      color: $white;
    }
  }
  a {
    color: $gold;
  }
}
