@import "src/scss/base";

.container {
  background-color: $lt-grey;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.table {
  display: flex;

  @include sizes(0, 1000) {
    flex-wrap: wrap;
    flex-direction: column;
  }

  @include sizes(0, 1200) {
    margin-bottom: 2rem;
  }
}

.infoContainer {
  @include text;
  @include column-size(2, 2);
  position: relative;

  @include sizes(0, 1400) {
    @include column-size(1, 2);
  }
}

.row {
  background: white;
  height: 85px;
  min-width: rem(400);
  display: table;
  width: 100%;
  margin: rem(2);

  p {
    display: table-cell;
    vertical-align: middle;
    padding: 0 rem(15) 0 rem(15);
  }

  @include sizes(0, 1400) {
    width: 98%;
    min-width: rem(300);
  }
}

.disclosureText {
  float: right;
}
