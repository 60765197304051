@import "src/scss/base";

.wrapper {
  @include container-default;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innerContainer {
  display: flex;
  flex-direction: column;

  @include sizes(0, 1200) {
    margin-bottom: 6rem;
  }
}

.iconLockup {
  @include text;
}

.header {
  @include subheader-small;
  margin: 5rem 0 1rem;

  @include sizes(0, 1200) {
    margin: 1rem 0 1rem;
  }
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: space-evenly;
  width: 85%;
}

.subText {
  width: 55%;
  font-size: rem(14);
  font-family: $sans-serif-thin;
  text-align: center;
  align-self: center;
}

.imageInner {
  display: flex;
  flex-direction: column;
}

.image {
  padding: rem(25);
  align-self: center;

  @include sizes(0, 600) {
    max-width: 150px;
  }

  // @include sizes(600, 1400) {
  //   max-width: 200px;
  // }
}
