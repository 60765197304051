@import "src/scss/base";

.containerDark {
  background: $lt-grey;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
  background: url(/images/home_social-images-background.png);
  background-repeat: no-repeat;
  background-position-y: center;

  @include sizes(0, 1200) {
    background-position: center;
    background-size: contain;
    padding-bottom: 2rem;
  }
}

.infoContainer {
  @include column-size(1.2, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
  }
}

.header {
  font-size: rem(24);

  @include sizes(0, 1200) {
    font-size: rem(18);
  }
}

.text {
  font-family: $sans-serif-thin;
  line-height: 1.5;
  font-size: rem(24);

  @include sizes(0, 1200) {
    font-size: rem(16);
  }
}

.list {
  @include check-list;
  font-size: rem(18);

  @include sizes(0, 1200) {
    font-size: rem(16);
  }
}

.link {
  @include blue-dotted-link;
}

.thin {
  font-family: $sans-serif;
}
