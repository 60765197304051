@import "src/scss/base";

.container {
  overflow-y: auto;
  color: $text;
  box-sizing: border-box;
  margin-top: $header-height;
  width: rem(370);
  box-shadow: -5px -1px 9px rgba(0, 0, 0, 0.12);
  background: $lt-grey;
  position: fixed;
  right: 0;
  z-index: 2;
  padding: rem(10) rem(10) rem(85) rem(10);
  transition: top 0.5s;

  @include sizes(0, 450) {
    width: 100%;
  }
}

.content {
  color: $text;
  min-height: 100%;
}

.innerContent {
  padding: rem(15);
  background: $white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin: 0 rem(5) rem(16) rem(5);
  border-radius: 2px;
}

.header {
  margin: 0;
  font-family: $sans-serif-thin;

  &::after {
    content: "";
    background-color: $gold;
    display: block;
    width: rem(215);
    height: rem(2);
  }
  font-size: 1rem;
  font-family: $sans-serif;
}

.list {
  margin: 0;
  font-size: rem(14);
  @include black-bullet-list;
  color: $text;
}

.innerList {
  @include black-circle-list;
  color: $text;
  display: block;
  margin-top: 1rem;
}

.linkWrapper {
  margin-top: rem(15);
  @include link-underline-effect;
}

.link {
  @include white-dotted-link;
  margin-left: rem(25);
}

.italic {
  font-style: italic;
}
