@import "src/scss/base";

.container {
  background: $lt-grey;
  position: relative;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include text;
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 50);
    margin: 0 0 6rem;
  }
}

.list {
  @include plain-list;
  line-height: 1.5;
  font-size: rem(14);
  color: $white;
  padding: 1rem;

  li {
    position: relative;
    margin: 0;
  }

  span {
    font-family: $sans-serif-bold;
  }
}

#hidden {
  display: none;
}

#dkList {
  color: $text;
}

.colorWrapper {
  display: flex;
  height: 230px;

  @include sizes(0, 1400) {
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
  }
}

.copyButton {
  @include unstyled-button;
  position: absolute;
  margin: rem(40) 0 0 rem(120) ;
  cursor: pointer;
}

.switchButton {
  @include unstyled-button;
  position: absolute;
  margin: rem(45) 0 0 rem(85);
  cursor: pointer;
}

.switchIcon {
  width: 25px;
}

#colorBlock {
  height: rem(225);
  min-width: rem(155);
}

.input {
  display: none;
}

.ltTeal {
  background: $lt_teal; //58A7AF
}
.teal {
  background: $teal; //34657F
}
.gold {
  background: $gold; //FBC600
}
.darkGrey {
  background: $dk_grey; //3B3C43
}
.ltGrey {
  background: $grey; //94969A
}

.hidden {
  display: none;
}

.underlined {
  text-decoration: underline solid $white;
  animation: underline-effect 1s;
  font-family:$sans-serif-bold;
}

.underlinedDark {
  text-decoration: underline solid $text;
  animation: underline-effect-dark 1s;
  font-family:$sans-serif-bold;
}

@keyframes underline-effect {
  0% {
    opacity: 0;
    text-decoration: none;
  }
  100% {
    opacity: 1;
    text-decoration: underline solid $white;
  }
}

@keyframes underline-effect-dark {
  0% {
    opacity: 0;
    text-decoration: none;
  }
  100% {
    opacity: 1;
    text-decoration: underline solid $text;
  }
}

.copyContainer {
  color: $lt-grey;
  position: absolute;
  z-index: 2;
  font-family: $sans-serif-bold;
  box-shadow: 0 0 3px $lt-grey;
  border-radius: rem(5);
  text-align: center;
  margin-top: rem(-10);
  margin-left: rem(5);
  font-size: 1.5rem;
  width: rem(145);
  background-color: transparentize($white, 0.7);
}
