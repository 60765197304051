/* ==== Media Query ==== */
@mixin sizes($min, $max: 0) {
  $min_str: $min/16 + rem;
  $max_str: ($max - 1)/16 + rem;

  @if $min >= 0 {
    @if $max > $min {
      @media screen and (min-width: $min_str) and (max-width: $max_str) {
        @content;
      }
    } @else {
      @media screen and (min-width: $min_str) {
        @content;
      }
    }
  } @else {
    @media screen and (max-width: $max_str) {
      @content;
    }
  }
}

/* ==== Containers & Responsive Mixins ==== */
@mixin container {
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
 
  @include sizes(0, 1200) {
    display: flex;
    flex-direction: column;
  }
} 

@mixin container-padding {
  @include sizes(0, $medium) {
    padding-left: rem($default_margin / 4);
    padding-right: rem($default_margin / 4);
  }
  @include sizes($medium, $large) {
    padding-left: rem($default_margin / 2);
    padding-right: rem($default_margin / 2);
  }
  @include sizes($large) {
    padding-left: rem($default_margin);
    padding-right: rem($default_margin);
  }
}

@mixin container-width {
  @include sizes(0, $medium) {
    width: rem($large + ($default_margin / 2));
  }
  @include sizes($medium, $large) {
    width: rem($large + $default_margin);
  }
  @include sizes($large) {
    width: rem($large + (2 * $default_margin));
  }
}

@mixin container-default {
  @include container;
  @include container-padding;
  @include container-width;
  height: $container-height;
  position: relative;

  @include sizes(0, 1200) {
    height: auto;
  }
}

// responsive columns
@mixin column-size($column_ct, $margin) {
  box-sizing: border-box;
  $total_margin: $margin * ($column_ct - 1);
  width: calc(#{100 / $column_ct}% - #{ceil($total_margin / $column_ct)}px);
  margin-right: #{$margin}px;
  &:nth-child(#{$column_ct}n) {
    margin-right: 0;
  }
}
