@import "src/scss/base";

.container {
  height: $container-height;
  position: relative;
  width: 100%;
}

.button {
  @include unstyled-button;
  margin-left: 90%;
  height: 50px;
  width: 25px;
  position: absolute;
  margin-top: -30%;

  @include sizes(0, 1400) {
    margin-left: 95%;
  }
}

.leftButton {
  @include unstyled-button;
  width: 25px;
  position: absolute;
  margin-top: -30%;
  margin-left: 5%;
  height: 50px;
}

.dotGroup {
  width: 100%;
  position: absolute;
  display: flex;
  margin-top: -5%;
}

.dots {
  display: block;
  margin: auto;

  button {
    margin: rem(5);
    @include unstyled-button;
    width: 50px;
    height: 5px;
    background: $grey;
  }

  button:disabled {
    background-color: $teal;
  }
}

.transitionEffect {
  width: 100%;
  flex-direction: column;
  transition: transform 1.5s;
}

.hidden {
  display: none;
}
