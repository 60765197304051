@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include text;
  @include column-size(2, 0);
  margin-left: rem(-250);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
    margin-left: rem(0);
    margin-bottom: 2rem;
  }
}

.list {
  @include black-bullet-list;
  li{
    margin: 1rem 0;
  }
}

.innerList {
  list-style-type: none;
}

.italic {
  font-style: italic;
}