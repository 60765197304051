/* Font stacks */

@font-face {
  font-family: "Synchrony Sans";
  font-display: fallback;
  src: url(/fonts/SynchronySans.woff) format("woff"),
    url(/fonts/SynchronySans.woff2) format("woff2"),
    url(/fonts/SynchronySans.ttf) format("truetype"),
    url(/fonts/SynchronySans.eot) format("embedded-opentype"),
    url(/fonts/SynchronySans.otf) format("opentype"),
    url(/fonts/SynchronySans.svg) format("svg");
  font-weight: 400;
}

@font-face {
  font-family: "Synchrony Sans Thin";
  font-display: fallback;
  src: url(/fonts/SynchronySans-Thin.woff) format("woff"),
    url(/fonts/SynchronySans-Thin.woff2) format("woff2"),
    url(/fonts/SynchronySans-Thin.ttf) format("truetype"),
    url(/fonts/SynchronySans-Thin.eot) format("embedded-opentype"),
    url(/fonts/SynchronySans-Thin.otf) format("opentype"),
    url(/fonts/SynchronySans-Thin.svg) format("svg");
  font-weight: 300;
}

@font-face {
  font-family: "Synchrony Sans Medium";
  font-display: fallback;
  src: url(/fonts/SynchronySans-Medium.woff) format("woff"),
    url(/fonts/SynchronySans-Medium.woff2) format("woff2"),
    url(/fonts/SynchronySans-Medium.ttf) format("truetype"),
    url(/fonts/SynchronySans-Medium.eot) format("embedded-opentype"),
    /* No otf available for this */ url(/fonts/SynchronySans-Medium.svg)
      format("svg");
  font-weight: 500;
}

@font-face {
  font-family: "Synchrony Sans Bold";
  font-display: fallback;
  src: url(/fonts/SynchronySans-Bold.woff) format("woff"),
    url(/fonts/SynchronySans-Bold.woff2) format("woff2"),
    url(/fonts/SynchronySans-Bold.ttf) format("truetype"),
    url(/fonts/SynchronySans-Bold.eot) format("embedded-opentype"),
    url(/fonts/SynchronySans-Bold.otf) format("opentype"),
    url(/fonts/SynchronySans-Bold.svg) format("svg");
  font-weight: 700;
}
