@import "./scss/base";

body {
  color: $text;
  margin: 0;
  width: 100%;
  font-family: $sans-serif;

  ::selection {
    background: $grey; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: $grey; /* Gecko Browsers */
  }
}

button:active {
  outline-color: $grey;
  border: $grey;
}

button:focus {
  outline: 1;
  outline-color: $grey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $sans-serif-bold;
}

.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
