@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include text;
  background: url(/images/style_guide_04-woman-man-laughing.jpg);
  background-attachment: scroll;
  // background-position: fixed;
  background-size: cover;
  height: 85%;
  width: 100%;
  margin-right: rem(-225);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;

  @include sizes(0, 1200) {
    margin-right: rem(0);
    height: 50vh;
    margin-bottom: 4rem;
    margin-left: 0;
  }
}

.button {
  @include unstyled-button;
  margin-left: 75%;
  height: 50px;
  cursor: pointer;

  @include sizes(0, 450) {
    margin-right: -39%;
    height: 50px;
  }
}

.photoIcon {
  @include unstyled-button;
  display: block;
  align-self: flex-start;
  position: absolute;
  right: 25px;
  margin-top: 25px;
  cursor: pointer;

  // @include sizes(0, 500) {
  //   align-self: flex-end;
  //   top: 195px;
  // }
  @include sizes(0, 1200) {
    // align-self: flex-end;
    // top: 160px;
    display: none;
  }
}

.lightBox {
  display: flex;

  a:hover {
    .imageDownloadIcon {
      opacity: 1;
      display: block;
      transition: all 0.2s ease-out;
      background-color: transparentize($lt-teal, 0.6);
      border-radius: 3px;
      padding: 1rem;
    }
  }
}

.downloadLink {
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.imageDownloadIcon {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 5;
}

.closeButton {
  @include unstyled-button;
  cursor: pointer;
}

.closeIcon {
  z-index: 10;
  width: 30px;
  position: absolute;
  right: 25px;
  top: 25px;
}
