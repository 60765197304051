@import "src/scss/base";

.container {
  background-image: url(/images/style_guide_06-voice-tone.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  font-size: rem(24);
  line-height: 1.5;
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include fontSize(18);
    @include column-size(1, 0);
    margin-bottom: 2rem;
  }
}

.button {
  @include unstyled-button;
  color: $link;
  font-weight: bold;
  cursor: pointer;

  @include sizes(0, 1200) {
    margin: 1rem 0;
    font-size: rem(14);
  }
}

.icon {
  vertical-align: middle;
  margin-right: rem(15);
}

audio {
  position: absolute;
  width: 100px;
  box-shadow: 0 0 8px $dk-grey;
  border-radius: 50px;
}
