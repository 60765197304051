@function rem($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: #{$size}px; // Fallback in px
  font-size: rem($size);
}

@mixin sizes($min, $max: 0) {
  $min_str: $min/16 + rem;
  $max_str: ($max - 1)/16 + rem;

  @if $min >= 0 {
    @if $max > $min {
      @media screen and (min-width: $min_str) and (max-width: $max_str) {
        @content;
      }
    } @else {
      @media screen and (min-width: $min_str) {
        @content;
      }
    }
  } @else {
    @media screen and (max-width: $max_str) {
      @content;
    }
  }
}
