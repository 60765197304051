@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
    margin-bottom: 2rem;
  }
}

.header {
  @include subheader-small;
}

.list {
  @include plain-list;
  margin-left: 2rem;
}

.link {
  @include blue-dotted-link;
}
