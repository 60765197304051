@mixin basic-button {
  border-radius: $border-radius;
  background-color: $med_blue;
  border: none;
  min-height: rem(36);
  display: block;
}

@mixin link-button {
  background-color: transparent;
  border: none;
  min-height: rem(36);
  display: block;
}

@mixin unstyled-button {
  background: none;
  border: none;
  font-size: 1rem;
  padding: 0;
}

@mixin focus-outline($color) {
  &:focus {
    outline: 2px solid $color !important;
  }
}

@mixin button-base {
  display: inline-block;
  border: 0;
  padding: rem(14) rem(25);
  align-self: center;
  text-align: center;
  box-sizing: border-box;
  line-height: 1;
  background-color: $gold;
  color: $text;
  font-family: $sans-serif-bold;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
  @include focus-outline($dk_grey);
}

@mixin button-hover {
  background-color: $text !important;
  color: #ffffff !important;
  border: 2px solid $white;
}

@mixin button-underline-effect {
  button {
    position: relative;
  }

  button::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  button:hover:before {
    visibility: visible;
    width: 100%;
  }
}

//STYLED LINKS
@mixin white-dotted-link {
  color: $white;
  text-decoration: none;
  border-bottom: 2px dotted $white;
  font-family: $sans-serif-bold;
}

@mixin blue-dotted-link {
  color: $link;
  text-decoration: none;
  border-bottom: 2px dotted $link;
  font-family: $sans-serif;
  font-family: $sans-serif-bold;

  &:hover {
    font-weight: bolder;
    border-bottom: 2px solid $link;
  }
}

@mixin link-underline-effect {
  a {
    position: relative;
  }

  a::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $white;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  a:hover:before {
    visibility: visible;
    width: 100%;
  }

  &:hover {
    border-bottom: none;
  }
}
@mixin link-underline-effect-dark {
  @include link-underline-effect;
  a::before {
    background-color: $teal !important;
  }
}
