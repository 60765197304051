@import "src/scss/base";

.footer {
  font-size: rem(14);
  position: absolute;
  margin: rem(-50) rem(50);
  font-family: $sans-serif-medium;


  @include sizes(0, 1200) {
    position: relative;
    padding: rem(25) rem(10) rem(48) rem(10);
    margin: 0;
  }
}
