@import "src/scss/base";

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include sizes(0, 1200) {
    margin-bottom: 2rem;
    justify-content: center;
   }
}

.infoContainer {
  @include text;
  @include column-size(2.5, 150);
  img {
    max-width: 350px;
  }

  @include sizes(480, 1200) {
    @include column-size(1, 150);
    img {
      width: 50%;
    }
   }

   @include sizes(0, 480) {
    @include column-size(1, 150);
    img {
      width: 75%;
    }
   }

}

.header {
  @include subheader-small;
}

.list {
  @include plain-list;
  font-family: $sans-serif-thin;
}

.bold {
  font-family: $sans-serif-bold;
}

.text {
  font-family: $sans-serif-thin;
}
