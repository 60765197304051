@import "src/scss/base";

.container {

  @include sizes(0, 1200) {
    background: #f0f0f0;
    padding-bottom:2rem;
  }
}

.infoContainer {
  @include text;
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
  }
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.header {
  @include subheader-small;
}
 