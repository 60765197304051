@import "src/scss/base";

.infoLockup {
  @include column-size(1.8, 50);
  @include text;  
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sizes(0, 1200) {
    @include column-size(1, 50);
    margin-top: 2rem;
  }
}

.header {
  @include header-underlined;
  max-width: 75%;
  line-height: 1;
}

.header1 {
  line-height: 1;
  @include fontSize(45);
  display: block;
  color: $text;
  font-family: $sans-serif-thin;
  padding: 0;
  text-transform: uppercase;

  @include sizes(0, 1200) {
    @include fontSize(36);
    margin: 1rem 0 0 0;
  }
}

.header2 {
  line-height: 1;
  @include fontSize(45);
  display: block;
  color: $text;
  font-family: $sans-serif-thin;
  padding: 0;
  text-transform: uppercase;

  @include sizes(0, 1200) {
    @include fontSize(36);
    margin: 0;
  }
}

.textContainer {
  font-family: $sans-serif-thin;
}

.textLockup {
  max-width: 75%;
  min-width:450px;

  @include sizes(0, 1200) {
    min-width:100%;

  }
}

.list {
  @include plain-list;
  max-width: 75%;
}

.subTitle {
  @include subheader-small;
}

#dashEffect {
  @include header-dash;
}
