@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include text;
}

.header {
  display: inline-block;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0 1rem 1.5rem 0;

  a {
    justify-content: flex-start !important;
  }
}

.button {
  @include unstyled-button;
}

#list {
  @include plain-list;
  font-size: 1.2rem;
  margin: 0 0 1.5rem 0;

  li {
    margin: rem(5) 0;
  }

  @include sizes(0, 1200) {
    font-size: 1rem;
  }
}

.regular {
  font-family: $sans-serif;
}

.listThin {
  font-family: $sans-serif-thin;
}

.listMed {
  font-family: $sans-serif-medium;
}
.listBold {
  font-family: $sans-serif-bold;
}

.upperCase {
  text-transform: uppercase;
}

#imgDownload {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 0;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
  @include column-size(1.5, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 50);
    margin-bottom: 2rem;
  }
  &:after {
    content: "";
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    cursor: pointer;
  }

  &:hover {
    &:after {
      width: 75%;
      height: 100%;
      position: absolute;
      border-radius: 3px;
      background-color: transparentize($lt-teal, 0.5);

      @include sizes(0, 1200) {
        width: 100%;
      }
    }

    img {
      opacity: 1;
      display: block;
      transition: all 0.3s ease-out;
    }
  }
}

.gridImage {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 5;

  @include sizes(0, 1200) {
    left: 50%;
  }
}
