$white: #FFF;
$black: #000; 

//HOME STAPLE COLORS
$teal: #34657F; //HOME TEAL
$lt_teal: #58A7AF; //HOME LIGHT TEAL
$gold: #FBC600; //HOME-SYF-GOLD
$dk_grey: #3B3C43; //HOME DARK GREY
$grey: #94969A; //HOME LIGHTER GREY

//Promo container colors
$dk_blue: #125370; //HOME DARK BLUE
$med_blue: $teal; //HOME LINK BLUE 
$lt_blue: #CCE4E6; //HOME LIGHT BLUE
$med_grey:#707177; //HOME GREY
$lt-grey: #F0F0F0; //HOME SLIDE BACKGROUND BASE

//Specific uses
$text: $dk_grey; //HOME TEXT COLOR
$link: $med_blue;
$header: $med_grey; //HOME HEADER COLOR
