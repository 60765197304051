@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.infoContainer {
  @include text;
  @include column-size(1.2, 0);

  @include sizes(0, 1400) {
    @include column-size(1, 0);
  }
}

.textLockup {
  color: $text;
  font-family: $sans-serif-thin;
}

.link {
  @include unstyled-button;
  @include blue-dotted-link;
  margin: 0 0.5rem;
  cursor: pointer;

  @include sizes(0, 1400) {
    max-width: 400px;
    flex-wrap: wrap;
  }
}

.bold {
  font-family: $sans-serif;
  font-family: $sans-serif-bold;
}

.listLockup {
  margin: rem(50) 0;
}

.list {
  @include plain-list;
}
