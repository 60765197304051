@import "src/scss/base";


.container {
  @include sizes(1200, 1400) {
    margin-top: 1rem;
  }
  @include sizes(0, 1200) {
    padding-bottom:2rem;
  }
}

.infoContainer {
  @include text;
  @include column-size(1.2, 0);

  @include sizes(0, 1300) {
    @include column-size(1, 0);
  }

  img {
    max-height: 100%;
  }
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;

  @include sizes(0, 1400) {
    flex-direction: column !important;
  }
}


.header {
  @include subheader-small;
  margin-bottom: -1rem;
}

.ctaWrapper {
  display: flex;
  justify-content: space-between;
}

.ctaHeader {
  font-size: rem(24);
  text-transform: uppercase;
  line-height: 1.2;
  margin: 0;
  color: $text;
    width: 65%;
    align-self: flex-end;  
    .bold {
    color: $teal;
  }
}

.ctaHeaderLeft {
  font-size: rem(24);
  text-transform: uppercase;
  line-height: 1.2;
  margin: 0;
  color: $text;
    width: 35%;
    align-self: flex-start;  
    .bold {
    color: $teal;
  }
}

.cardsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  color: $text;
  margin-top: 1rem;

  @include sizes(0, 900) {
    flex-direction: column-reverse;
    margin: auto;
  }

  @include sizes(900, 0) {
    // max-width: 85%;
  }
  @include sizes(900, 1200) {
    margin-top: 1rem;
  }
}

.mobileBorder {
  display: flex;
  // padding: 1rem;
  border-radius: 10px;
  flex-direction: column;
  // max-width: 208px;


  .cardBlue {
    min-height: 185px;
    &:first-of-type {
      margin: 1rem 0 0;
    }
    margin: 1rem 0;
  }

  .sublineBlue {
    margin-top: rem(-10);
    padding-bottom: rem(10);
    color: $white;
    font-family: $sans-serif-medium;
  }
}

.ctaHeaderM {
  color: $text;
  font-size: 1.2rem;
  margin-top: -1rem;
  display: none;
  font-size: rem(24);
  text-transform: uppercase;
  line-height: 1.2;
  margin: 0;
  max-width: 300px;
  .bold {
    color: $teal;
  }
  @include sizes(0, 900) {
    display: block;
  }
}
.cardBlue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-basis: calc(30% - 2rem);
  background-color: $teal;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  color:white;

  @include sizes(0, 1400) {
    max-width: 300px;
  }
}

.cardWhite {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gold;
  flex-basis: calc(30% - 2rem);
  padding: 1rem;
  border-radius: 10px;
  justify-content: center;
  @include sizes(0, 900) {
    margin: 1rem 0;
    min-height: 185px;
  }
  @include sizes(0, 1400) {
    max-width: 300px;
  }

  .cardNumber {
    color: $teal;
    font-size: 3.5rem;
    @include sizes(0, 900) {
      font-size: 4rem;
    }
  }
}

// .cardInner {
//   border: 3px solid $gold;
//   flex-basis: calc(30% - 2rem);
//   padding: 1rem;
//   border-radius: 10px;
// }

.cardNumber {
  color: $white;
  font-family: $sans-serif;
  font-size: 3rem;
  line-height: 1;
  margin: 0;
  font-weight: normal;

  @include sizes(0, 900) {
    font-size: 3.5rem;
  }
}

.subline {
  margin: 0;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  font-family: $sans-serif-medium;
  @include sizes(0, 900) {
    font-size: 0.9rem;
    // font-family: $sans-serif-medium;
  }
}

.sublineBlue {
  margin: 0;
  font-size: 14px;
  text-align: center;
  color: $teal;
  text-transform: uppercase;
  margin: 0.2rem 0 0.1rem;
  text-align: center;
  font-family: $sans-serif-medium;

  @include sizes(0, 900) {
    font-family: $sans-serif-medium;
    font-size: 1rem;
    margin-top: rem(-10);
    padding-bottom: rem(10);
  }
}

.sublineWhite {
  margin: 0;
  font-size: 14px;
  text-align: center;
  // color: $teal;
  text-transform: uppercase;
  margin: 0.2rem 0 0.1rem;
  text-align: center;

  @include sizes(0, 900) {
    font-family: $sans-serif-medium;
    font-size: 1rem;
    margin-top: rem(-10);
    padding-bottom: rem(10);
  }
}

// .plus {
//   color: $dk_blue;
//   margin: 0;
//   padding: 0 1rem;
//   font-size: 1.7rem;
//   line-height: 1;
//   text-transform: uppercase;
//   background-color: $white;
//   transform: translateY(-2rem);

//   @include sizes(0, 900) {
//     font-size: 1.5rem;
//   }
// }

.ctaDisclosure {
  text-align: center;
  margin: 0.1rem 0;
  font-size: 14px;

  @include sizes(0, 900) {
    font-size: 0.9rem;
  }
}

// .disclosure {
//   font-family: $sans-serif-thin;
//   color: $text;
//   font-size: rem(10);
//   margin-top: 2rem;
//   // max-width: 85%;

//   @include sizes(0, 900) {
//     margin: auto;
//   }
// }

#imgDownload {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  margin: 0;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
  @include sizes(1200, 0) {
    transform: scale(.8);
  }
  &:after {
    content: "";
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;
    cursor: pointer;
  }

  &:hover {
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 3px;
      background-color: transparentize($lt-teal, 0.5);
    }

    img {
      opacity: 1;
      display: block;
      transition: all 0.3s ease-out;
    }
  }

  @include sizes(1200, 1400) {
    margin-left: 50px;
  }

  @include sizes(0, 900) {
    @include column-size(1, 0);
    justify-content: center;
  }
}

.gridImage {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 5;
}

.bold {
  font-family: $sans-serif-bold;
}
