@import "src/scss/base";

.button {
  @include unstyled-button;
  position: absolute;
  margin-left: 45%;
  z-index:10;
  cursor: pointer;

  @include sizes(0, 1200) {
    margin-left: 40%;
  }
}
