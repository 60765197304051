@import "src/scss/base";

.container {
  @include container-default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.innerContainer {
  @include column-size(1.2, 0);

    @include sizes(0, 1200) {
      @include column-size(1, 0);
      margin: 2rem 0;
    }

    @include sizes(1200, 1400) {
      margin-left:50px;
    }
}

.disclosure {
  font-family: $sans-serif-thin;
  font-size: rem(10);
  margin-top: 2rem;
  max-width: 85%;
}

.header {
  @include subheader-small;
}

.list {
  @include check-list;
}
