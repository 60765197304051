@import "src/scss/base";

.container {
  background: $lt-grey;
  font-family: $sans-serif-thin;
  padding-top:.5rem;

  @include sizes(0, 1200) {
    padding-top:2px;
  }
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infoContainer {
  @include column-size(1.8, 150);

  @include sizes(0, 1400) {
    @include column-size(1, 150);
  }
}

.list {
  @include plain-list;
}

.subList {
  list-style: none;
  font-size: rem(14);
}

.bold {
  font-family: $sans-serif-bold;
}

.iconLockup {
  @include text;
  @include column-size(2, 150);

  @include sizes(0, 1400) {
    @include column-size(1, 150);
  }
}

.header {
  @include subheader;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  width: 375px;
  align-self: center;

  @include sizes(0, 600) {
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
  }

  @include sizes(600, 1400) {
    margin:1rem auto 2rem auto;
  }

  a {
    width:150px;
  }

  img {
    width:150px;

  }
}

.button {
  @include unstyled-button;
  margin-left: rem(375);
}
