@import "src/scss/base";

.container {
  background: $teal;
  color: $white;
  padding: 6rem 0;

  @include sizes(0, 600) {
    padding: 3rem 0;
  }

  @include sizes(600, 1000) {
    padding: 6rem 0;
  }
}

.innerContainer {
  @include container-default;
  display: flex;
  align-items: center;
}

.infoContainer {
  @include column-size(1.2, 0);

  @include sizes(0, 1200) {
    @include column-size(1, 0);
  }
}

.text {
  font-family: $sans-serif-thin;
  line-height: 1.5;
  font-size: rem(24);

  @include sizes(0, 1200) {
    font-size: rem(18);

  }
}

.bold {
  font-family: $sans-serif-bold;
}

.link {
  @include white-dotted-link;
}

.infoLockup {
  @include column-size(1.5, 0);
  @include text;
  height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include sizes(0, 1200) {
    @include column-size(1, 0);
    height: auto;
  }
}

.header {
  @include header-underlined;
  color: $white !important;
  max-width: 75%;
}

#dashEffect {
  @include header-dash;
}